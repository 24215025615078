import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=bc381890&scoped=true&"
import script from "./Search.vue?vue&type=script&lang=js&"
export * from "./Search.vue?vue&type=script&lang=js&"
import style0 from "./Search.vue?vue&type=style&index=0&id=bc381890&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc381890",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VAvatar,VListItem,VListItemContent,VListItemTitle})
