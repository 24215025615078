/**
 * Warnings component is a collection of warning tools and displays on network errors
 */
<template>
  <div class="warnings">
    <v-snackbar v-model="snackbar" color="error" timeout="15000">
      {{ errorDescription }}
      <template v-slot:action="{ attrs }">
        <v-btn icon color="blue" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Settings",
  data: () => ({ snackbar: false }),
  watch: {
    networkError(val) {
      this.snackbar = true;
    },
  },
  mounted: function () {},
  computed: mapState(["networkError", "errorDescription"]),
};
</script>

<style lang="scss" scoped>
</style>
