  /**
   * Condition component has the selected citys current weather conditions displayed
   */
<template>
  <div class="condition ma-5" v-if="selectedCity">
    <v-img
      transition="scale-transition"
      width="200"
      :src="`img/weather-icons/${selectedCity.WeatherIcon}.svg`"
    ></v-img>
    <!-- {{ selectedCity }} -->
    <h2 class="temperature">
      {{ selectedCity.Temperature[scale].Value
      }}<span class="scale">&deg;{{ scaleType(selectedCity) }}</span>
    </h2>
    <h3 class="description">{{ selectedCity.WeatherText }}</h3>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Tools from "../../../mixins/tools.js";

export default {
  name: "Condition",
  mixins: [Tools],
  data: () => ({}),
  mounted: function () {
    this.$store.dispatch("currentConditions");
  },
  methods: {},
  computed: {
    ...mapState(["selectedCity", "selectedWeather"]),
  },
};
</script>

<style lang="scss" scoped>
.condition {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.temperature {
  position: relative;
  font-size: 4rem;
  font-weight: 300;
  text-align: center;
  line-height: 65px;
}
.scale {
  position: absolute;
  top: -14px;
  right: -33px;
  font-size: 35px;
}
.description {
  font-size: 2rem;
  font-weight: 300;
}
</style>

