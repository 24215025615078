// Tools mixin is a colloection of useful converters and formaters.

import { mapState } from "vuex";

const mixin = {
    methods: {
        scaleType: function (t) {
            return t.Temperature[this.scale].Unit
        },
        temperature: function (value) { // when scale is not provided by api
            let temp = !this.temperatureScale
                ? value * (9 / 5) + 32 // to F
                : value; // keep default C
            return temp.toFixed(1); // built-in js func to limit number length
        },
        accuDay: function (date) {
            return new Date(date).toString().split(" ")[0];
        },
    },
    computed: {
        ...mapState(["temperatureScale"]),
        scale: function () {
            return this.temperatureScale ? "Metric" : "Imperial"
        }
    },
}

export default mixin