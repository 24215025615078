   
  /**
   * components displays 5 day weather forecast according to selected city 
   */
<template>
  <v-row class="forecast">
    <v-row v-if="otherDays" class="cards" align="center" justify="center">
      <v-card
        v-for="(item, i) in otherDays.DailyForecasts"
        :key="i"
        flat
        :class="`card ${
          accuDay(item.Date) == accuDay(selectedCity.LocalObservationDateTime)
            ? 'selected'
            : ''
        }`"
      >
        <p class="max">
          {{ temperature(item.Temperature.Maximum.Value) }}&deg;
        </p>
        <v-img
          transition="scale-transition"
          width="128"
          :src="`img/weather-icons/${item.Day.Icon}.svg`"
        ></v-img>
        <p class="date">{{ accuDay(item.Date) }}</p>
      </v-card>
    </v-row>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Tools from "../../../mixins/tools.js";

export default {
  name: "FiveDayWeather",
  mixins: [Tools],
  data: () => ({}),
  methods: {},
  watch: {
    selectedCity(val) {
      this.$store.dispatch("FiveDayWeather");
    },
  },
  computed: {
    ...mapState(["otherDays", "selectedCity", "temperatureScale"]),
  },
};
</script>
<style lang="scss" scoped>
.forecast {
  overflow-y: auto;
  width: 100%;
}
.cards {
  padding-top: 15px;
  padding-bottom: 7px;
  flex-wrap: nowrap;
  .card {
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #0e1a2888;
    border-radius: 40px;
    padding: 10px;
    // margin-bottom: 20px;
    &:not(:last-of-type) {
      margin-right: 20px;
    }
    &.selected {
      color: white;
      background-color: #abe9cd;
      background-image: linear-gradient(
        180deg,
        #16bff6 0%,
        #169def 35%,
        #1270f7 100%
      );
      border: 2px solid #2086bb;
      padding-top: 20px;
      padding-bottom: 20px;
      box-shadow: 0 0 15px #053a80 !important;
    }
  }
}
.date {
  text-transform: uppercase;
}
</style>
