  /**
   * Header component has the city name flag and add to favorite buttons
   */
<template>
  <v-row class="featured-header" align="center" justify="space-between">
    <v-avatar size="32" class="mr-4">
      <v-img
        width="200px"
        :src="`https://www.countryflags.io/${selectedCityName.countryId}/shiny/64.png`"
      ></v-img>
    </v-avatar>
    <h3 class="city">{{ selectedCityName.name }}</h3>
    <div class="fav-functions">

      <v-btn icon class="icon-btn" title="in favorites" v-if="favorites.find(({cityName}) => this.selectedCityName.name === cityName)" @click="removeFromFavorites"
        ><v-icon class="icon" color="#ff5440" size="32">mdi-heart</v-icon></v-btn
      >
      <v-btn icon class="icon-btn" title="add to favorites" v-else @click="addToFavorites"
        ><v-icon class="icon"  size="32">mdi-heart-outline</v-icon></v-btn
      >

    </div>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Tools from "../../../mixins/tools.js";

export default {
  name: "Header",
  mixins: [Tools],
  data: () => ({}),
  methods: {
    addToFavorites: function () {
      this.$store.commit("addToFavorites");
    },
    removeFromFavorites: function () {
      this.$store.commit("removeFromFavorites");
    },
  },
  computed: {
    ...mapState(["selectedCityName", "favorites"]),
  },
};
</script>

<style lang="scss" scoped>
$favColor: #ff5440;
.featured-header {
  width: 100%;
  padding: 0 25px;
}
.icon-btn {
  // margin-right: 20px;
  &:hover {
    .icon {
      color: $favColor;
    }
  }
}
.selected {
  color: $favColor;
}
.add-to-favorites {
  background: $favColor;
}
.city {
  font-weight: 400;
}
</style>

