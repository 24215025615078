<template>
  <v-app>
    <nav class="nav" color="transparent">
      <h1 class="logo">Weathery.</h1>
      <v-spacer></v-spacer>
      <Tabs />
      <Settings />
    </nav>

    <Warnings />
    <Loading />
    
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Settings from "./components/general/Settings";
import Tabs from "./components/general/Tabs";
import Warnings from "./components/general/Warnings";
import Loading from "./components/general/Loading";

export default {
  name: "App",
  components: { Settings, Tabs, Warnings, Loading },
  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
// non scoped general style
.v-application--wrap {
  // height: 100vh;
  padding: 10px;
}
* {
  box-sizing: border-box;
}
p,
h1,
h2,
h3,
h4 {
  margin: 0 !important;
}
// Vuetify row class
.row {
  margin: 0 !important;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 25px;
  @media only screen and (max-width: 600px) {
    .settings {
      flex-basis: 100%;
      padding-top: 5px;
      justify-content: unset !important;
      .switch-wrap:first-of-type {
        border-left: unset;
      }
    }
  }
}
</style>
