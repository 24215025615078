/**
 * Search component with the help of vuetifys autocomplete for displaying results based on typed letters
 */
<template>
  <div class="search" v-show="!loading">
    <v-autocomplete
      v-model="select"
      :search-input.sync="search"
      :loading="searchLoading"
      :items="results"
      cache-items
      item-text="LocalizedName"
      append-icon="mdi-magnify"
      placeholder="Enter a name of a city"
      solo
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title> No matching results</v-list-item-title>
        </v-list-item>
      </template>

      <template v-slot:item="{ item }">
        <button class="wrap" @click="selectedItem(item)" v-if="item">
          <v-avatar tile size="64" class="mr-2" >
            <img
              :src="`https://www.countryflags.io/${item.Country.ID}/shiny/64.png`"
            />
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="item.LocalizedName"></v-list-item-title>
          </v-list-item-content>
        </button>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapState } from "vuex";

import auto from "../../temp-api/autocomplete.json"; // temp testing file

export default {
  name: "Main",
  data: () => ({ searchLoading: false, search: null, select: null }),
  watch: {
    search(val) {
      this.searchLoading = true;
      this.$store.dispatch("autocomplete", { q: val }).then(() => {
        this.searchLoading = false;
      });
    },
  },
  methods: {
    selectedItem: function (item) {
      this.$store.dispatch("currentConditions", { city: item });
    },
  },
  computed: mapState(["results", "loading"]),
};
</script>

<style lang="scss" scoped>
.search {
  margin: 0 auto;
  max-width: 550px;
}
.wrap {
  left: 0;
  top: 0;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
  // background: brown;
}
</style>
