<template>
  <div class="main" >
    <div class="comp-wrap">
      <Search />
    </div>
    <div class="comp-wrap">
      <Featured />
    </div>
    <div class="comp-wrap">
      <FiveDayWeather />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Search from "@/components/home/Search";
import Featured from "@/components/home/Featured";
import FiveDayWeather from "@/components/home/featured/FiveDayWeather";

export default {
  name: "Main",
  components: {
    Search,
    Featured,
    FiveDayWeather,
  },
    computed: {
    ...mapState(["loading"]),
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
