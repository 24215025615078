  /**
   * Tabs component contains the apps navigation tabs
   */
<template>
  <div class="tabs">
    <v-tabs class="">
      <v-tab :to="{ path: '/' }">
        <v-icon>mdi-home</v-icon>
      </v-tab>
      <v-tab :to="{ path: '/Favorites' }">
        <v-badge
          bottom
          overlap
          color="green"
          :dot="favorites.length === 0 ? true : false"
          :content="favorites.length"
        >
          <v-icon>mdi-heart-multiple</v-icon>
        </v-badge>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Settings",
  methods: {},
  computed: mapState(["temperatureScale", "favorites"]),
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 700px) {
  .v-tab {
    max-width: 30px !important;
  }
  .tab-text {
    display: none;
  }
}
</style>
