import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 🏙️ city
    defaultCity: { name: 'Tel Aviv', countryId: 'IL', key: 215854, initiated: false },
    locationKey: null,
    selectedCityName: { name: null, key: null, countryId: null },
    selectedCity: null,
    otherDays: null,
    // ⛳ general
    apiKey: 'VapsMZ6Pz4958gAcvB4L1UOx0G3L5fva',
    results: [],
    favorites: [],
    temperatureScale: true, // true = celcius, false = farenheight
    loading: true, 
    // 🛑 errors
    networkError: false,
    errorDescription: false
  },
  mutations: {
    citySelected(state, payload) {
      let cityName = payload.cityName;
      let result = state.results.find(({ LocalizedName }) => LocalizedName === cityName);
      state.selectedCity.key = result.Key;
    },
    initiateError(state) {
      state.temperatureScale = !state.temperatureScale;
    },
    scaleChange(state) {
      state.temperatureScale = !state.temperatureScale;
    },
    addToFavorites(state) { // builds favoirte objects and than adds it to favorites array
      console.log(state.selectedCity);
      let favorite = {
        cityName: state.selectedCityName.name,
        key: state.selectedCityName.key,
        countryId: state.selectedCityName.countryId,
        temperature: { metric: state.selectedCity.Temperature.Metric.Value, imperial: state.selectedCity.Temperature.Imperial.Value },
        weatherDescription: state.selectedCity.WeatherText,
        icon: state.selectedCity.WeatherIcon
      }
      state.favorites.push(favorite)
    },
    removeFromFavorites(state, payload) { // builds favoirte objects and than adds it to favorites array
      let favorites = state.favorites;
      let name = payload ? payload : state.selectedCityName.name
      let index = favorites.indexOf(favorites.find(({ cityName }) => name === cityName));
      console.log(index);
      state.favorites.splice(index, 1)
    },
  },
  actions: {
    // API calls to Accuweather 🗺️ 
    autocomplete({ state, commit, rootState }, payload) { // receives a string and returns an array of cities by city names similar to search input
      let q = payload.q;
      let url = `locations/v1/cities/autocomplete?q=${q}&apikey=${state.apiKey}`;
      Vue.axios.get(url).then((response) => {
        let data = response.data;
        state.results = data;
        console.log(data);
      }).catch(function (error) {
        console.log(error);
        state.networkError = true;
        state.errorDescription = error;
      });
    },
    currentConditions({ state, commit, rootState }, payload) { // receives current weather conditions based off of selected location
      if (state.defaultCity.initiated) { // initialised as in when the app first opens, display default if false
        state.locationKey = payload.city.Key;
        state.selectedCityName.name = payload.city.LocalizedName;
        state.selectedCityName.key = payload.city.Key;
        state.selectedCityName.countryId = payload.city.Country.ID;
      } else {
        state.locationKey = state.defaultCity.key;
        state.selectedCityName.name = state.defaultCity.name;
        state.selectedCityName.countryId = state.defaultCity.countryId;
        state.defaultCity.initiated = true;
      }
      // 

      let url = `currentconditions/v1/${state.locationKey}?apikey=${state.apiKey}&details=true`;
      Vue.axios.get(url).then((response) => {
        if (state.loading) state.loading = false;
        state.selectedCity = response.data[0];
        state.gotDefaultData = true;
      }).catch(function (error) {
        console.log(error);
        state.networkError = true;
        state.errorDescription = error;
      });
    },
    FiveDayWeather({ state, commit, rootState }) { // receives future forcast conditions based off of selected location
      let url = `forecasts/v1/daily/5day/${state.locationKey}?apikey=${state.apiKey}&details=true&metric=true`;
      Vue.axios.get(url).then((response) => {
        state.otherDays = response.data;
      }).catch(function (error) {
        console.log(error);
        state.networkError = true;
        state.errorDescription = error;
      });
    },
  },
})
