/**
 * Feratured component is the main card displaying the featured weather info for selected or default weather
 */
<template>
  <v-card dark class="mx-auto pa-3 mb-5 card main-card" v-show="!loading">
    <Header transition="scale-transition" />
    <Condition transition="scale-transition" />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Header from "@/components/home/featured/Header";
import Condition from "@/components/home/featured/Condition";

export default {
  name: "Featured",
  components: {
    Header,
    Condition,
  },
  data: () => ({}),
  methods: {},
  computed: {
    ...mapState(["defaultCity", "selectedCity", "selectedWeather", "loading"]),
  },
};
</script>

<style lang="scss" scoped>
$borderRad: 50px;
.main-card {
  position: relative;
  max-width: 900px;
  width: 100%;
  border-radius: $borderRad + 10 !important;
  z-index: 1;
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  &::before {
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 97%;
    height: 100%;
    background: #053a80;
    border-radius: $borderRad + 8;
    z-index: -1;
  }
  &::after {
    top: 0;
    left: 0;
    border: 3px solid #2b88f2;
    width: 100%;
    height: 100%;
    background: brown;
    background-color: #abe9cd;
    background-image: linear-gradient(
      180deg,
      #16bff6 0%,
      #169def 35%,
      #1270f7 100%
    );
    box-shadow: 0px 0px 15px #053a80;
    border-radius: $borderRad;
    z-index: -1;
  }
}
</style>

