<template>
  <Main />
</template>

<script>
  import Main from '../components/Main'

  export default {
    name: 'Home',

    components: {
      Main,
    },
  }
</script>
