  /**
   * Settings component contains the apps settings such as scale and dark/light modes control 
   */
<template>
  <v-row justify="end" class="settings">
    <div class="switch-wrap">
      <button
        :class="`btn ${scale ? '' : 'pressed'} ${dark ? '' : 'light'}`"
        title="Celsius"
        @click="scale = !scale"
      >
        <v-icon>mdi-temperature-celsius</v-icon>
      </button>
      <button
        :class="`btn ${scale ? 'pressed' : ''} ${dark ? '' : 'light'}`"
        title="Fahrenheit"
        @click="scale = !scale"
      >
        <v-icon>mdi-temperature-fahrenheit</v-icon>
      </button>
    </div>

    <div class="switch-wrap">
      <button
        :class="`btn  ${dark ? '' : 'pressed'} ${dark ? 'light' : ''}`"
        :title="`Switch to ${dark ? 'light mode' : 'dark mode'}`"
        @click="dark = !dark"
      >
        <v-icon>mdi-{{ dark ? "lightbulb" : "lightbulb-on-outline" }}</v-icon>
      </button>
    </div>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Settings",
  data: () => ({ dark: true, scale: false }),
  watch: {
    dark(val) {
      this.$vuetify.theme.dark = val;
    },
    scale(val) {
      this.$store.commit("scaleChange");
    },
  },
  mounted: function () {
    this.$vuetify.theme.dark = true;
  },
  computed: mapState(["temperatureScale"]),
};
</script>

<style lang="scss" scoped>
.switch-wrap {
  display: flex;
  align-items: center;
  border-left: 1px solid gray;
  height: 30px;
  .icon-label {
    cursor: pointer;
    margin-right: 10px;
    padding-left: 10px;
  }
}
$primary: #2196f3;
.btn {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background: none;
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 1px 2px $primary, 2px 3px $primary, 3px 4px $primary;
  margin: 0 7px;
  transition: 0.4s;
  background-repeat: no-repeat;
  background-size: cover;
  &.light {
    border: 2px solid #2196f3;
  }

  &:hover {
    position: relative;
    top: 2px;
    left: 2px;
    border: 2px solid #2196f3;
    box-shadow: 1px 2px $primary, 2px 3px $primary, 2px 4px 12px $primary;
  }
  &.pressed {
    position: relative;
    top: 2px;
    left: 2px;
    box-shadow: 0px 0px 12px $primary;
  }
  &:focus {
    outline: none;
  }
}
</style>
